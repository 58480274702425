import React, { useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import { inject, observer } from 'mobx-react'
import RequestsTable from 'src/components/RequestsTable/RequestsTable'
import newRequest from 'src/assets/images/orderStatuses/newWhite.svg'
import DatesStore from 'src/stores/DatesStore'
import { getPeriodDaysString } from 'src/helpers/getReportPeriodDate'
import ReportOrderDetailsDrawer from 'src/views/Reports/components/ReportOrderDetailsDrawer/ReportOrderDetailsDrawer'
import RequestsFilterStore from 'src/views/RequestsManagement/stores/RequestsFilterStore'
import NewRequestFilters from 'src/views/RequestsManagement/components/NewRequestFilters/NewRequestFilters'
import { useIntl } from 'react-intl'
import ReportInfoDrawer from 'src/views/Reports/components/ReportInfoDrawer/ReportInfoDrawer'
import SelectedReportStore from 'src/views/Reports/stores/SelectedReportStore'
import ProgressBackdrop from 'src/components/ProgressBackdrop/ProgressBackdrop'
import TransactionConfirmation from '../../../components/TransactionConfirmation/TransactionConfirmation'
import { isDealer } from '../../../services/AccountService'
import addToReport from '../../../assets/images/reportStatuses/addToReport.svg'
import massDelete from '../../../assets/images/reportStatuses/massDelete.svg'
import SelectedReport from './SelectedReport/SelectedReport'
import CreateReportStore from 'src/views/Reports/stores/CreateReportStore'
import AddRequestsToReport from './AddRequestsToReport'

function ReportDetailsAdvanced({
    selectedReportId,
    onClose,
    scrollIsAlreadyLocked,
    isReportDetailsOpen,
    updateRequestManagmentTable,
    closeCreateReport,
    openAddRequestsToReport,
    closeAddRequestsToReport,
    isAddRequestsToReportOpen,
}) {
    const intl = useIntl()
    const [selectedReportStore] = useState(() => new SelectedReportStore({ id: selectedReportId, onClose }))
    const [createReportStore] = useState(() => new CreateReportStore({ closeCreateReport }))
    const [filterStore] = useState(new RequestsFilterStore())
    const { data, openReportDrawer, ordersStore, reportOrderDetailsStore } = selectedReportStore
    const { setCurrentOpenedRowIndex, currentOpenedRowIndex, openReportDetails } = reportOrderDetailsStore
    const { getMonthName } = new DatesStore()
    const { id, month, year, created, interval, creationTime } = data || {}
    const { selectOrdersStore } = createReportStore

    useEffect(() => {
        if (!isAddRequestsToReportOpen) {
            selectedReportStore.reLoad()
            ordersStore.reLoad({
                page: 0,
                size: ordersStore.pageSize,
                reportId: selectedReportId,
            })
        }
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                onClose()
            }
        }
        window.addEventListener('keydown', handleEsc)

        return () => {
            window.removeEventListener('keydown', handleEsc)
        }
    }, [isAddRequestsToReportOpen])

    useEffect(() => {
        if (!isAddRequestsToReportOpen) {
            selectedReportStore.reLoad()
        }
    }, [ordersStore.total])

    const reportTitle = useMemo(
        () =>
            !interval
                ? intl.formatMessage(
                      {
                          id: 'Отчет №{id} за {month} {year} г.',
                          defaultMessage: 'Отчет №{id} за {month} {year} г.',
                      },
                      {
                          id: selectedReportId,
                          month: getMonthName(month),
                          year: year,
                      }
                  )
                : intl.formatMessage(
                      {
                          id: 'Отчет №{id} за {days} {month} {year} г.',
                          defaultMessage: 'Отчет №{id} за {days} {month} {year} г.',
                      },
                      {
                          id,
                          month: getMonthName(month, true),
                          year: year,
                          day: created,
                          days: getPeriodDaysString(interval, month, year),
                      }
                  ),
        [data]
    )

    return selectedReportStore.isReady ? (
        isAddRequestsToReportOpen ? (
            <AddRequestsToReport reportId={data.id} closeAddRequestsToReport={closeAddRequestsToReport} />
        ) : (
            //prop stickyTitle делает заголовок окна фиксированным при скролле

            <Box>
                <SelectedReport
                    data={data}
                    openReportDrawer={() => openReportDrawer(isDealer())}
                    onClose={onClose}
                    reportTitle={reportTitle}
                />

                <RequestsTable
                    filterFromReports={
                        <Box mb={1} mr={1} ml={1} pt={2}>
                            <NewRequestFilters
                                gray
                                filterRowStyles={{ background: '#fff' }}
                                totalCollectionLength={ordersStore.total}
                                filtersStore={filterStore}
                                defaultParams={{ reportId: id }}
                                reportMonth={month}
                                reportRequests={true}
                                ordersStore={ordersStore}
                                selectOrdersStore={selectOrdersStore}
                                actionButtonProps={{
                                    name: intl.formatMessage({
                                        id: selectOrdersStore.selectedOrders?.size
                                            ? 'Убрать из отчёта'
                                            : 'Добавить заявки...',
                                    }),
                                    onClick: () =>
                                        selectOrdersStore.selectedOrders?.size
                                            ? reportOrderDetailsStore.removeOrder([...selectOrdersStore.selectedOrders])
                                            : openAddRequestsToReport(), // добавить метод для удаления из отчёта
                                    icon: selectOrdersStore.selectedOrders?.size ? massDelete : addToReport,
                                    disabled: selectedReportStore.data.status !== 1,
                                    tooltip:
                                        selectedReportStore.data.status !== 1
                                            ? 'Добавление заявок в отчет доступно только для отчетов в статусе "Создан"'
                                            : null,
                                }}
                                scrollIsAlreadyLocked={scrollIsAlreadyLocked}
                            />
                        </Box>
                    }
                    store={ordersStore}
                    filterStore={filterStore}
                    onRowClick={reportOrderDetailsStore.openOrderDetails}
                    rowsPerPageOptions={[50, 100, 200, 300, 400, 500]}
                    reportRequests={true}
                    doublePagination={true}
                    setCurrentOpenedRowIndex={setCurrentOpenedRowIndex}
                    currentOpenedRowIndex={currentOpenedRowIndex}
                    hideUninformative={true}
                    remuveRequest={reportOrderDetailsStore.removeOrder}
                    isReportDetailsOpen={isReportDetailsOpen}
                    selectOrdersStore={selectOrdersStore}
                    reportStatus={selectedReportStore.data.status}
                />
                <ReportOrderDetailsDrawer
                    store={reportOrderDetailsStore}
                    reportStatus={selectedReportStore.data.status}
                    ordersStore={ordersStore}
                    openReportDetails={openReportDetails}
                    onTop={true}
                    currentOpenedRowIndex={reportOrderDetailsStore.currentOpenedRowIndex}
                    onRowClick={reportOrderDetailsStore.openOrderDetails}
                    // isReportDetailsOpen={isReportDetailsOpen}
                    disableLinks={true} // запретить открытие отчета по ссылке в истории заявок
                />
                <ReportInfoDrawer
                    onTop={true}
                    store={selectedReportStore}
                    updateRequestManagmentTable={updateRequestManagmentTable}
                />
                <TransactionConfirmation />
            </Box>
        )
    ) : (
        <ProgressBackdrop local={true} isOpen={true} />
    )
}

export default observer(ReportDetailsAdvanced)
