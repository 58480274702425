import React, { useMemo, useState } from 'react'
import { inject, observer } from 'mobx-react'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import avatarIcon from '../../../assets/images/common/avatar.svg'
import { when } from 'mobx'
import { gState } from 'src/utils/controllers/GlobalStateController'
import { getComp } from 'src/utils/utils/DI'
import { getStyles } from 'src/utils/utils/commonUtils'
import requestFiltersStyles from '../../RequestsManagement/styles/requestFiltersStyles'
import { closeModalFn, openModalFn } from 'src/utils/controllers/ModalController'
import { isAdmin, isDealer } from 'src/services/AccountService'
import moment from 'moment/moment'
import NewFilters from '../../../components/NewFilters/NewFilters'
import ReportFilterChip from './ReportFilterChip/ReportFilterChip'
import DatesStore from '../../../stores/DatesStore'
import { getYears } from 'src/helpers/getYears'
import newRequest from 'src/assets/images/orderStatuses/newWhite.svg'
import downLoad from 'src/assets/images/common/downloadWhite.svg'
import downLoadGray from 'src/assets/images/common/downloadGray.svg'
import StoragesStore from 'src/views/Reports/stores/StoragesStore'

function ReportsFilters({ reportStore }) {
    const { reportsFilterStore, reportStorageStore } = reportStore
    const { shelvs, getStorageName, shelvName, boxName } = StoragesStore
    const intl = gState['intl']
    const statusStore = getComp('StatusesStore')
    const dealersStore = getComp('DealersStore')
    const [datesStore] = useState(new DatesStore())
    const classes = getStyles(requestFiltersStyles)

    const onSearch = () => {
        openModalFn['progress-backdrop']()
        const { makeParamCopy, makeHighlightTrue, setSelectedFilters, getParams } = reportsFilterStore
        const param = getParams()
        makeParamCopy(param)
        when(
            () => reportStore.isLoaded,
            () => {
                makeHighlightTrue()
                setSelectedFilters()
                closeModalFn['progress-backdrop']()
            }
        )
        return reportStore.reLoad({
            ...param,
            page: 0,
            size: reportStore.pageSize,
        })
    }

    const handleDeleteChip = (propName, multiselectValue) => {
        reportsFilterStore.deleteFilter(propName, multiselectValue)
        onSearch()
    }

    const actionButtonProps = useMemo(
        () =>
            isDealer()
                ? {
                      name: intl.formatMessage({ id: 'Новый отчет' }),
                      onClick: reportStore.openAddReportDialog,
                      icon: newRequest,
                  }
                : isAdmin()
                ? {
                      name: intl.formatMessage({ id: 'Сохранить в файл' }),
                      onClick: reportStorageStore.downloadFile,
                      icon: reportStorageStore.isTableRowSelected ? downLoad : downLoadGray,
                      disabled: !reportStorageStore.isTableRowSelected,
                  }
                : null,
        [gState['roles'], reportStorageStore.isTableRowSelected]
    )

    const filtersData = [
        [
            [
                {
                    type: 'select',
                    multiple: true,
                    propName: 'statuses',
                    name: intl.formatMessage({ id: 'Статус' }),
                    collection: statusStore.reportStatuses,
                    setMenuItem: (data) => {
                        return (
                            <MenuItem value={data.id === 0 ? '' : data.id}>
                                <Box className={'aln-center'}>
                                    <img alt={''} src={data.imageSrc} />
                                    <Box ml={1} sx={classes.selectTextContainer}>
                                        <Typography variant={'subtitle2'} sx={classes.selectText}>
                                            {data.name}
                                        </Typography>
                                    </Box>
                                </Box>
                            </MenuItem>
                        )
                    },
                },
                {
                    type: 'select',
                    multiple: true,
                    propName: 'financialSourceIds',
                    name: intl.formatMessage({ id: 'Тип оплаты'  }),
                    placeholder: intl.formatMessage({ id:  'Тип оплаты' }),
                    collection: statusStore.financialSources.filter((el) => el.id !== 4),
                    setMenuItem: (el) => {
                        return (
                            <MenuItem key={el.id} value={el.id === 0 ? '' : el.id}>
                                <Box className={'aln-center'}>
                                    <img alt={''} src={el.imageSrc} />
                                    <Box ml={1}>
                                        <Typography variant={'subtitle2'}>{el.name}</Typography>
                                    </Box>
                                </Box>
                            </MenuItem>
                        )
                    },
                },
            ],

            [
                {
                    type: 'date',
                    propName: 'creationDateFrom',
                    name: intl.formatMessage({ id: 'Дата создания' }),
                },
                {
                    type: 'date',
                    propName: 'creationDateTo',
                    name: intl.formatMessage({ id: 'Дата создания' }),
                },
            ],
            [
                {
                    type: 'date',
                    propName: 'closeDateFrom',
                    name: intl.formatMessage({ id: 'Дата закрытия' }),
                    minDate: moment(window.SAT.config.minDateOperationalSummary).utc().format('YYYY-MM-DD HH:mm'),
                    maxDate: moment().utc(true).format('YYYY-MM-DD HH:mm'),
                },
                {
                    type: 'date',
                    propName: 'closeDateTo',
                    name: intl.formatMessage({ id: 'Дата закрытия' }),
                },
            ],
            ...(isAdmin()
                ? [
                      {
                          type: 'select',
                          propName: 'dealerIds',
                          multiple: true,
                          name: intl.formatMessage({ id: 'Автор' }),
                          collection: dealersStore.collection,
                          setMenuItem: (data) => {
                              return (
                                  <MenuItem value={data.subjectId} sx={{ padding: '20px 12px' }}>
                                      <Box className={'aln-center'} height={20}>
                                          <img alt={''} src={avatarIcon} height={20} width={20} />
                                          <Box ml={1} sx={classes.selectTextContainer}>
                                              <Typography
                                                  variant={'body2'}
                                                  sx={{ ...classes.selectTextContainer, ...classes.selectText }}
                                              >
                                                  {data.displayName}
                                              </Typography>
                                          </Box>
                                      </Box>
                                  </MenuItem>
                              )
                          },
                      },
                  ]
                : []),
            {
                type: 'input',
                propName: 'reportId',
                name: intl.formatMessage({ id: 'Номер отчета' }),
            },
            [
                {
                    type: 'select',
                    propName: 'year',
                    name: intl.formatMessage({ id: 'Год' }),
                    setMenuItem: (data) => <MenuItem value={data}>{data}</MenuItem>,
                    collection: getYears(),
                },
                {
                    type: 'select',
                    propName: 'month',
                    name: intl.formatMessage({ id: 'Месяц' }),
                    setMenuItem: (data) => (
                        <MenuItem value={data}>{datesStore.getMonthName(data, false, true)}</MenuItem>
                    ),
                    collection: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                },
            ],
            ...(isAdmin()
                ? [
                      [
                          {
                              type: 'select',
                              propName: 'shelvs',
                              name: shelvName,
                              collection: shelvs,
                              setMenuItem: (data) => <MenuItem value={data.value}>{data.key}</MenuItem>,
                          },
                          {
                              type: 'select',
                              propName: 'boxes',
                              multiple: true,
                              name: boxName,
                              collection: reportsFilterStore.getBoxesFromShelv(),
                              disabled: !reportsFilterStore.data['shelvs'],
                          },
                      ],
                  ]
                : []),
        ],
    ]

    const setChip = (params, withAction) => (
        <ReportFilterChip {...params} onClick={withAction && handleDeleteChip} getStorageName={getStorageName} />
    )
    return (
        <Box mb={2}>
            <NewFilters
                title={intl.formatMessage(
                    {
                        id: 'Найдено отчетов {reports}',
                        defaultMessage: 'Найдено отчетов {reports}',
                    },
                    {
                        reports: reportStore.total,
                    }
                )}
                setChip={setChip}
                totalCollectionLength={reportStore.total}
                filtersStore={reportsFilterStore}
                filtersData={filtersData}
                onSearch={onSearch}
                filtersContainerHeight={'150px'}
                actionButtonProps={actionButtonProps}
            />
        </Box>
    )
}

export default inject('reportStore')(observer(ReportsFilters))
