import { inject, observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import CustomTable from '../../../utils/components/CustomTable/CustomTable'
import Tooltip from '@mui/material/Tooltip'
import moment from 'moment/moment'
import { useIntl } from 'react-intl'
import { getComp } from 'src/utils/utils/DI'
import DatesStore from '../../../stores/DatesStore'
import TableSortIcon from '../../../components/customElements/TableSortIcon/TableSortIcon'
import { isAdmin, isDealer } from 'src/services/AccountService'
import { getPeriodDaysString } from 'src/helpers/getReportPeriodDate'
import { Box } from '@mui/system'
import { Checkbox, IconButton, useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import StackActionStore from 'src/utils/components/StackActions/StackActionStore'
import StackActions from 'src/utils/components/StackActions/StackActions'
import { checkMobileDevice } from '../../../utils/utils/commonUtils'
import arrowRight from '../../../assets/images/common/arrowRight.svg'
import StoragesStore from 'src/views/Reports/stores/StoragesStore'
import { isAdministration, isDealerOperator, isLooker, isOperator } from '../../../services/AccountService'
import { getIconFinancialSource, getTitleFinancialSource } from '../../../helpers/switchFinancialSource'

function ReportsTable({ reportStore }) {
    const {
        collection = [],
        pageSize,
        pageNumber,
        reversPage,
        openReportDetails,
        total,
        checkIsRevers,
        tableCollection,
        isLoaded,
        setPageSizeAndReload,
        reportStorageStore,
        getAllReportsNumber,
        allReports,
    } = reportStore
    const statusesStore = getComp('StatusesStore')

    const intl = useIntl()
    const authorStore = getComp('AuthorsStore')
    const {
        getStorageData,
        checkIsReportSelected,
        onClickReportCheckBox,
        isTableRowSelected,
        onClickTableCheckBox,
        tableCheckedStatus,
        clearSelectedReports,
    } = reportStorageStore
    const [stackActionStore] = useState(() => new StackActionStore({ store: reportStorageStore }))
    const { getReportStorageData, checkIsReportAvailableToSelect, shelvName, boxName, storagesName } = StoragesStore
    const [datesStore] = useState(new DatesStore())
    const theme = useTheme()

    useEffect(() => {
        // получаю количество всех отчетов для постоянного отображения
        if (total && !allReports) {
            getAllReportsNumber()
        }
    }, [isLoaded])

    const getReportName = (params) => {
        return !params.interval
            ? intl.formatMessage(
                  {
                      id: 'Отчет №{id} за {month} {year} года',
                      defaultMessage: 'Отчет №{id} за {month} {year} года',
                  },
                  {
                      id: params.id,
                      month: datesStore.getMonthName(params.month),
                      year: params.year,
                  }
              )
            : intl.formatMessage(
                  {
                      id: 'Отчет №{id} за {days} {month} {year} года',
                      defaultMessage: 'Отчет №{id} за {days} {month} {year} года',
                  },
                  {
                      id: params.id,
                      month: datesStore.getMonthName(params.month, true),
                      year: params.year,
                      days: getPeriodDaysString(params.interval, params.month, params.year),
                  }
              )
    }

    const columns = useMemo(() => {
        let columns = [
            ...[
                {
                    propName: 'checkBox',
                    headerElement: '',
                    minWidth: '56px',
                    renderCell: (report) => {
                        const isReportAvailable = checkIsReportAvailableToSelect(report.status)
                        const checked = checkIsReportSelected(report)
                        const onChange = () => {
                            onClickReportCheckBox(report)
                        }
                        const onClick = (e) => e.stopPropagation()
                        return isReportAvailable ? (
                            <Checkbox checked={checked} onChange={onChange} onClick={onClick} />
                        ) : null
                    },
                },
            ],
            {
                propName: 'status',
                field: 'status',
                headerName: intl.formatMessage({ id: 'Статус и номер' }),
                renderCell: (params) => {
                    const name = statusesStore.getStatusName(params.status, true)
                    const icon = statusesStore.getStatusImage(params.status, true)
                    return (
                        <Tooltip title={name} placement={'top-start'}>
                            <Box
                                sx={{
                                    padding: '8px 4px',
                                    borderRadius: '6px',
                                    background: `${theme.palette.gray.veryLightGray}`,
                                    display: 'flex',
                                    gap: '4px',
                                }}
                            >
                                <img alt={''} src={icon} style={{ width: '16px', height: '16px' }} />
                                <Typography variant={'body2'}>№{params.id}</Typography>
                            </Box>
                        </Tooltip>
                    )
                },
            },
            {
                propName: 'financial',
                field: 'financial',
                headerName: intl.formatMessage({ id: 'Оплата' }),

                renderCell: (params) => {
                    return (<>
                        {getIconFinancialSource(params.financialSourceId)?<Tooltip
                            title={
                                statusesStore?.financialSourcesName[params.financialSourceId - 1] ||
                                getTitleFinancialSource(params.financialSourceId)
                            }
                            placement={'top-start'}
                            >
                            <img
                                alt={
                                    statusesStore?.financialSourcesName[params.financialSourceId - 1] ||
                                    getTitleFinancialSource(params.financialSourceId)
                                }
                                src={getIconFinancialSource(params.financialSourceId)}
                                />
                        </Tooltip>: null}
                        {params.financialSourceId===4?null: <Typography ml={2}  sx={{ fontSize: '14px', fontWeight: '500', width: '28%' }}>
                                {statusesStore?.financialSourcesName[params.financialSourceId - 1] ||
                                    getTitleFinancialSource(params.financialSourceId)}
                            </Typography>}
                                </>
                    )
                },
            },
            ...(isDealer()
                ? []
                : [
                      {
                          propName: 'author',
                          field: 'author',
                          headerName: intl.formatMessage({ id: 'Автор' }),
                          renderCellValue: (params) => {
                              return authorStore.getName(params.authorId)?.name
                          },
                          headerStart: true,
                          justifyContent: 'flex-start',
                      },
                  ]),
            {
                propName: 'month',
                field: 'month',
                headerName: intl.formatMessage({ id: 'Наименование' }),
                renderCellValue: getReportName,
                headerStart: true,
                justifyContent: 'flex-start',
                paddingBodyCell: '4px',
                textAlign: 'left',
            },
            {
                propName: 'orders',
                field: 'orders',
                headerName: intl.formatMessage({ id: 'Кол-во заявок в отчёте' }),
                renderCellValue: (params) => params.ordersCount,
                customTextStyles: {
                    [theme.breakpoints.down('sm')]: { fontWeight: '500', fontSize: '16px' },
                },
                headerStart: true,
            },
            {
                propName: 'creationTime',
                field: 'creationTime',
                headerName: intl.formatMessage({ id: 'Дата создания' }),
                handleClick: () => reversPage('creationtime'),
                icon: <TableSortIcon isReverse={checkIsRevers('creationtime')} />,
                minWidth: '110px',
                customTextStyles: {
                    [theme.breakpoints.down('sm')]: { fontSize: '16px', fontWeight: '400' },
                },
                renderCellValue: (params) =>
                    params.creationTime ? moment(params.creationTime).format('DD.MM.YYYY') : null,
            },
            {
                propName: 'closeTime',
                field: 'closeTime',
                headerName: intl.formatMessage({ id: 'Дата закрытия' }),
                minWidth: '110px',
                renderCellValue: (params) => (params.closeTime ? moment(params.closeTime).format('DD.MM.YYYY') : null),
            },
            ...(isAdmin()
                ? [
                      {
                          propName: 'storage',
                          field: 'storage',
                          headerName: storagesName,
                          renderCellValue: (params) => getReportStorageData(params.storage, 1),
                          headerStart: true,
                          justifyContent: 'flex-start',
                      },
                      {
                          propName: 'storage',
                          field: 'storage',
                          headerName: shelvName,
                          renderCellValue: (params) => getReportStorageData(params.storage, 2),
                          headerStart: true,
                          justifyContent: 'flex-start',
                      },
                      {
                          propName: 'storage',
                          field: 'storage',
                          headerName: boxName,
                          renderCellValue: (params) => getReportStorageData(params.storage, 3),
                          headerStart: true,
                          justifyContent: 'flex-start',
                      },
                  ]
                : []),
        ]
        if (isDealer() && checkMobileDevice()) {
            columns.push({
                propName: 'details',
                field: 'details',
                headerName: intl.formatMessage({ id: 'Подробнее' }),
                renderCell: () => (
                    <IconButton>
                        <img alt={'open row'} src={arrowRight} />
                    </IconButton>
                ),
            })

            columns = columns.filter((item) => {
                const mobileColumns = ['status', 'orders', 'creationTime', 'details']
                return mobileColumns.includes(item.propName) ? item : null
            })
        }

        isLooker() || isDealer() ? (columns = columns.filter((item) => item.propName !== 'checkBox')) : columns

        return columns
    }, [])
    const onPageChange = useCallback((event, page) => {
        if (page >= 0) {
            reportStore.loadPageAction(page)
            if (isAdmin()) {
                clearSelectedReports()
            }
        }
    }, [])

    const number = '0211214544';
const num = `${number.substring(0, 3)} ${number.substring(3, 6)} ${number.substring(6, number.length)}`;



    const onPageSizeChange = useCallback((event) => {
        setPageSizeAndReload(event.target.value)
    }, [])

    return (
        <CustomTable
            rowsPerPageOptions={[50, 100]}
            doublePagination={true}
            rows={tableCollection}
            columns={columns}
            pageSize={pageSize}
            page={pageNumber}
            onRowClick={openReportDetails}
            total={total}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            isLoading={!isLoaded}
            actionsBlock={
                isAdmin() ? (
                    <StackActions stackActionStore={stackActionStore} />
                ) : isDealer() && checkMobileDevice() ? (
                    <Typography sx={{ fontSize: '11px', fontWeight: '400', paddingTop: '5px' }}>
                        Всего отчетов: {allReports}
                    </Typography>
                ) : null
            }
            headCheackbox={{
                onChange: () => onClickTableCheckBox(collection),
                allChecked: () => !!isTableRowSelected,
                toggletoggleGroupSelected: () => 0 === 1,
            }}
        />
    )
}

export default inject('reportStore')(observer(ReportsTable))
