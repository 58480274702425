export const contentStyles = () => ({
    container: {
        display: "flex",
        alignItems: "flex-start",
        padding: "24px 0 0"
    },
    textBold: {
        fontWeight: 500,
        margin: "0 4px"
    },
    buttonContainer: {
        marginTop: "32px",
        display: "flex",
        flexWrap: "nowrap",
        gap: "16px"
    },
    toastText: {
        fontFamily: "PT Root UI",
        color: "#000"
    }
})