import {observer} from "mobx-react"
import CustomDialog from "src/components/CustomDialog/CustomDialog"
import {closeModalFn, isModalOpen} from "src/utils/controllers/ModalController"
import {useIntl} from "react-intl"
import React from "react"
import Content from "./Content/Content"


function AddToStorageDialog(){
    const intl = useIntl()
    return(
        <CustomDialog
            open={isModalOpen["add-to-storage"]}
            onClose={closeModalFn["add-to-storage"]}
            title={intl.formatMessage({id: "Подтверждение операции"})}
            styles={{maxWidth: "429px", padding: "8px 16px 16px"}}
            smallTitle={true}
        >
            <Content/>
        </CustomDialog>
    )
}

export default observer(AddToStorageDialog)