import { observer } from 'mobx-react'
import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Box from '@mui/material/Box'
import RequestsTable from '../../../components/RequestsTable/RequestsTable'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import DetailsPage from 'src/components/DetailsPage/DetailsPage'
import CreateReportStore from 'src/views/Reports/stores/CreateReportStore'
import NewRequestFilters from 'src/views/RequestsManagement/components/NewRequestFilters/NewRequestFilters'
import addToReport from '../../../assets/images/reportStatuses/addToReport.svg'

function CreateReport({ closeCreateReport, created, setCreated }) {
    const intl = useIntl()
    const [createReportStore] = useState(() => new CreateReportStore({ closeCreateReport }))
    const { createReport, ordersStore, selectOrdersStore, filterStore, defaultParams } = createReportStore
    const { selectedOrders } = selectOrdersStore

    useEffect(() => {
        ordersStore.reLoad(defaultParams)
    }, [])

    useEffect(() => {
        if (created) {
            createReport()
        }
    }, [created])

    return (
        <DetailsPage
            title={intl.formatMessage({ id: 'Формирование отчета' })}
            onClose={closeCreateReport}
            propStyles={{ padding: '0' }}
        >
            <Box sx={{ marginBottom: '10px' }}>
                <NewRequestFilters
                    filtersStore={filterStore}
                    selectOrdersStore={selectOrdersStore}
                    isReportCreate={true}
                    totalCollectionLength={ordersStore.total}
                    filterRowStyles={{ background: '#fff' }}
                    ordersStore={ordersStore}
                    defaultParams={defaultParams}
                    actionButtonProps={{
                        name: <FormattedMessage id={'Добавить заявки...'} />,
                        onClick: () => {
                            setCreated(true)
                        },
                        icon: addToReport,
                        disabled: !selectOrdersStore.isOrdersSelected,
                    }}
                />
                {/* <Button
                    onClick={createReport}
                    variant={'contained'}
                    disabled={!selectOrdersStore.isOrdersSelected}
                    // size={'small'}
                >
                    <FormattedMessage id={'Сформировать отчет'} />
                </Button> */}
            </Box>

            <Box className={'aln-center'} mb={1} sx={{ display: 'flex', justifyContent: 'end', paddingLeft: '15px' }}>
                {selectedOrders.size === 0 ? (
                    <Box sx={{ height: '18.75px' }} />
                ) : (
                    <Typography variant={'h5'} sx={{ mr: 2, textAlign: 'right' }}>
                        <FormattedMessage id={'Заявок выбрано'} /> {selectedOrders.size}
                    </Typography>
                )}
            </Box>
            <RequestsTable
                // containerStyle={{
                //     maxHeight: "calc(100vh - var(--appbar-height) - var(--footer-height) - 64px - 32px - 72px - 56px - 76px - 24px - 16px - 56px)"
                // }}
                store={ordersStore}
                filterStore={filterStore}
                selectOrdersStore={selectOrdersStore}
                rowsPerPageOptions={[50, 100, 200, 300, 400, 500]}
                addReport
                isReportCreate={true}
                doublePagination={true}
                frontendPagination={true}
            />
        </DetailsPage>
    )
}

export default observer(CreateReport)
