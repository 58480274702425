import RightDrawer from "src/components/customElements/RightDrawer/RightDrawer"
import {observer} from "mobx-react"
import Content from "./Content/Content"
import React from "react"
import {useIntl} from "react-intl"
import {closeModalFn, isModalOpen} from "src/utils/controllers/ModalController"

function ReportStorageDrawer(){
    const intl = useIntl()
    return (
        <RightDrawer
            open={isModalOpen["storage-drawer"]}
            onClose={closeModalFn["storage-drawer"]}
            title={intl.formatMessage({id: "Разместить на хранение"})}
        >
            <Content/>
        </RightDrawer>
    )
}

export default observer(ReportStorageDrawer)