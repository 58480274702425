import ReportsFilters from 'src/views/Reports/components/ReportsFilters'
import ReportsTable from 'src/views/Reports/components/ReportsTable'
import React, {useEffect} from 'react'
import {inject, observer} from 'mobx-react'
import {isAdmin} from 'src/services/AccountService'
import ReportStorageDrawer from './ReportStorageDrawer/ReportStorageDrawer'
import {disposeModal, regModal} from 'src/utils/controllers/ModalController'
import AddToStorageDialog from './AddToStorageDialog/AddToStorageDialog'
import StoragesStore from 'src/views/Reports/stores/StoragesStore'


function ReportsContent({reportStore}) {
    useEffect(() => {
        const modals = ['storage-drawer', 'add-to-storage']
        reportStore.reLoad()
        if (isAdmin()) {
            modals.forEach(regModal)
            StoragesStore.loadStorageTypes() // после получения типов вызывается метод получения данных о хранении
        }
        return () => {
            reportStore.setData([])
            modals.forEach(disposeModal)
        }
    }, [])
    return (
        <>
            <ReportsFilters />
            <ReportsTable />
            <ReportStorageDrawer />
            <AddToStorageDialog />
        </>
    )
}

export default inject('reportStore')(observer(ReportsContent))
