import {getStyles} from "src/utils/utils/commonUtils"
import {contentStyles} from "src/views/Reports/components/AddToStorageDialog/Content/contentStyles"
import React from "react"
import {inject, observer} from "mobx-react"
import {Box} from "@mui/system"
import infoIcon from "src/assets/images/common/info_blue.svg"
import Typography from "@mui/material/Typography"
import {FormattedMessage} from "react-intl/lib"
import {css} from "@emotion/css"
import {closeModalFn, modalData} from "src/utils/controllers/ModalController"
import {Button} from "@mui/material"
import {getDeclensionOfTheWord} from "src/helpers/declensionOfTheWord"
import {openModalFn} from "../../../../../utils/controllers/ModalController"

function AddToStorageContent({reportStore}) {
    const classes = getStyles(contentStyles)
    const {reportStorageStore, reLoad} = reportStore
    const {selectedReports, openNotifier} = reportStorageStore
    const {warehouse, shelv, box} = modalData["add-to-storage"]
    const accessAction = () => {
        openModalFn["progress-backdrop"]()
        openNotifier(
            <Typography variant={"body1"} sx={classes.toastText}>
                <FormattedMessage id={"Выполняется размещение"} />
                <span className={css(classes.textBold)}>{selectedReports?.length || 0}</span>
                {getDeclensionOfTheWord(
                    ["отчёта", "отчётов", "отчётов", "отчёта", "отчётов", "отчётов"],
                    selectedReports?.length || 0
                )}
                <FormattedMessage id={" по адресу хранения"} />
                <span className={css(classes.textBold)}>
                    {warehouse?.name}> {shelv?.name}> {box?.name}
                </span>
            </Typography>,
            reLoad
        )
    }
    return (
        <>
            <Box sx={classes.container}>
                <img src={infoIcon} />
                <Box ml={1} pt={0.5}>
                    <Typography variant={"body1"}>
                        <FormattedMessage id={"Подтвердить размещение"} />
                        <span className={css(classes.textBold)}>{selectedReports?.length || 0}</span>
                        {getDeclensionOfTheWord(
                            ["отчёта", "отчётов", "отчётов", "отчёта", "отчётов", "отчётов"],
                            selectedReports?.length || 0
                        )}
                        <FormattedMessage id={" по следующему адресу хранения?"} />
                    </Typography>
                    <Box mt={2}>
                        <Typography variant={"subtitle1"}>{warehouse?.name} ></Typography>
                        <Typography variant={"subtitle1"}>{shelv?.name} ></Typography>
                        <Typography variant={"subtitle1"}>{box?.name}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={classes.buttonContainer}>
                <Button variant={"contained"} color={"primary"} onClick={accessAction} fullWidth={true}>
                    <FormattedMessage id={"Подтвердить"} />
                </Button>
                <Button
                    variant={"outlined"}
                    color={"primary"}
                    onClick={closeModalFn["add-to-storage"]}
                    fullWidth={true}
                >
                    <FormattedMessage id={"Отмена"} />
                </Button>
            </Box>
        </>
    )
}

export default inject("reportStore")(observer(AddToStorageContent))
