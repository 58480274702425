import { observer } from 'mobx-react'
import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Box from '@mui/material/Box'
import RequestsTable from '../../../components/RequestsTable/RequestsTable'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import DetailsPage from 'src/components/DetailsPage/DetailsPage'
import CreateReportStore from 'src/views/Reports/stores/CreateReportStore'
import NewRequestFilters from 'src/views/RequestsManagement/components/NewRequestFilters/NewRequestFilters'
import addToReport from '../../../assets/images/reportStatuses/addToReport.svg'
import { addRequestToReport, getRequestToReport } from '../../../services/ApiService'
import { notifySuccess } from '../../../services/ToastifyService/ToastifyService'
import { closeModalFn } from '../../../utils/controllers/ModalController'

function AddRequestsToReport({ closeAddRequestsToReport, reportId }) {
    const intl = useIntl()
    const [createReportStore] = useState(() => new CreateReportStore({ closeAddRequestsToReport, isAddRequestsToReportOpen: true }))
    const { createReport, ordersStore, selectOrdersStore, filterStore, defaultParams } = createReportStore
    const { selectedOrders } = selectOrdersStore
   
    
    useEffect(() => {
        ordersStore.reLoad({reportId: reportId, params: {size: 2000}})
        
    }, [])

    const handleAddRequestsToReport = () => {
        addRequestToReport(reportId, { orderIds: [...selectedOrders] }).then(() => {
            notifySuccess('Заявки добавлены в отчет')
            closeAddRequestsToReport()
        })
    }
    
    return (
        <DetailsPage
            title={intl.formatMessage({ id: 'Формирование отчета' })}
            onClose={closeAddRequestsToReport}
            propStyles={{ padding: '0' }}
        >
            <Box sx={{ marginBottom: '10px' }}>
                <NewRequestFilters
                    filtersStore={filterStore}
                    selectOrdersStore={selectOrdersStore}
                    isReportCreate={true}
                    search={reportId}
                    isAddRequestsToReportOpen={true}
                    totalCollectionLength={ordersStore.total}
                    filterRowStyles={{ background: '#fff' }}
                    ordersStore={ordersStore}
                    defaultParams={defaultParams}
                    actionButtonProps={{
                        name: <FormattedMessage id={'Добавить заявки...'} />,
                        onClick: handleAddRequestsToReport,
                        icon: addToReport,
                        disabled: !selectOrdersStore.isOrdersSelected,
                    }}
                />
            </Box>

            <Box className={'aln-center'} mb={1} sx={{ display: 'flex', justifyContent: 'end', paddingLeft: '15px' }}>
                {selectedOrders.size === 0 ? (
                    <Box sx={{ height: '18.75px' }} />
                ) : (
                    <Typography variant={'h5'} sx={{ mr: 2, textAlign: 'right' }}>
                        <FormattedMessage id={'Заявок выбрано'} /> {selectedOrders.size}
                    </Typography>
                )}
            </Box>

            <RequestsTable
                store={ordersStore}
                filterStore={filterStore}
                selectOrdersStore={selectOrdersStore}
                rowsPerPageOptions={[50, 100, 200, 300, 400, 500]}
                addReport
                isReportCreate={true}
                doublePagination={true}
                frontendPagination={true}
            />
        </DetailsPage>
    )
}

export default observer(AddRequestsToReport)
