import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import document from '../../../../../assets/images/reportStatuses/document.svg'
import { useTheme } from '@emotion/react'
import { getComp } from '../../../../../utils/utils/DI'
import { getIconFinancialSource, getTitleFinancialSource } from '../../../../../helpers/switchFinancialSource'
import StoragesStore from 'src/views/Reports/stores/StoragesStore'
import moment from 'moment'

function InformationReport({ data, openReportDrawer }) {
    const theme = useTheme()
    const statusesStore = getComp('StatusesStore')
    const authorStore = getComp('AuthorsStore')
    const name = statusesStore.getStatusName(data.status, true)
    const icon = statusesStore.getStatusImage(data.status, true)
    const { getReportStorageData } = StoragesStore
    useEffect(() => {
        authorStore.load() 
    },[])
    
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', paddingX: '15px' }}>
            <Table aria-label='simple table'>
                <TableHead>
                    <TableRow >
                        <TableCell
                            sx={{ width: '10%', border: 'none', padding: 0, paddingTop: '10px', fontSize: '13px', color: '#5F5F5F' }}
                        >
                            Статус и номер
                        </TableCell>
                        <TableCell
                            sx={{ width: '10%', border: 'none', padding: 0, paddingTop: '10px', fontSize: '13px', color: '#5F5F5F' }}
                        >
                            Оплата
                        </TableCell>
                        <TableCell
                            sx={{ width: '10%', border: 'none', padding: 0, paddingTop: '10px', fontSize: '13px', color: '#5F5F5F' }}
                        >
                            Автор
                        </TableCell>
                        <TableCell
                            sx={{ width: '10%', border: 'none', padding: 0, paddingTop: '10px', fontSize: '13px', color: '#5F5F5F' }}
                        >
                            Кол-во заявок
                        </TableCell>
                        <TableCell
                            sx={{ width: '10%', border: 'none', padding: 0, paddingTop: '10px', fontSize: '13px', color: '#5F5F5F' }}
                        >
                            Дата создания
                        </TableCell>
                        <TableCell
                            sx={{ width: '500%', border: 'none', padding: 0, paddingTop: '10px', fontSize: '13px', color: '#5F5F5F' }}
                        >
                            Дата закрытия
                        </TableCell>
                        {/* <TableCell
                            sx={{ width: '40%', border: 'none', padding: 0, paddingTop: '10px', fontSize: '13px', color: '#5F5F5F' }}
                        >
                            Место хранения документа
                        </TableCell> */}
                    </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow >
                        <TableCell sx={{ width: '10%', border: 'none', padding: 0 ,paddingBottom: '10px' }}>
                            <Tooltip ml={'14px'} title={name} placement={'top-start'}>
                                <Box
                                    sx={{
                                        padding: '8px 4px',
                                        borderRadius: '6px',
                                        background: `${theme.palette.gray.veryLightGray}`,
                                        display: 'flex',
                                        width: 'fit-content',
                                        gap: '4px',
                                    }}
                                >
                                    <img alt={''} src={icon} style={{ width: '16px', height: '16px' }} />
                                    <Typography variant={'body2'}>№{data.id}</Typography>
                                </Box>
                            </Tooltip>
                        </TableCell>
                        <TableCell sx={{ width: '10%', border: 'none', padding: 0 ,paddingBottom: '10px' }}>
                            <Box ml={'10px'}>
                                {getIconFinancialSource(data.financialSourceId) ? (
                                    <Tooltip
                                        title={
                                            statusesStore?.financialSourcesName[data.financialSourceId - 1] ||
                                            getTitleFinancialSource(data.financialSourceId)
                                        }
                                        placement={'top-start'}
                                    >
                                        <img
                                            alt={
                                                statusesStore?.financialSourcesName[data.financialSourceId - 1] ||
                                                getTitleFinancialSource(data.financialSourceId)
                                            }
                                            src={getIconFinancialSource(data.financialSourceId)}
                                        />
                                    </Tooltip>
                                ) : null}
                            </Box>
                        </TableCell>
                        <TableCell sx={{ width: '10%', border: 'none', padding: 0 ,paddingBottom: '10px', fontSize: '14px' }}>
                            {authorStore.getName(data.authorId)?.name}
                        </TableCell>
                        <TableCell sx={{ width: '10%', border: 'none', padding: 0 ,paddingBottom: '10px', fontSize: '14px' }}>
                            {data.ordersCount}
                        </TableCell>
                        <TableCell sx={{ width: '10%', border: 'none', padding: 0 ,paddingBottom: '10px', fontSize: '14px' }}>
                            {data.creationTime ? moment(data.creationTime).format('DD.MM.YYYY') : null}
                        </TableCell>
                        <TableCell sx={{ width: '50%', border: 'none', padding: 0 ,paddingBottom: '10px', fontSize: '14px' }}>
                            {data.closeTime ? moment(data.closeTime).format('DD.MM.YYYY') : null}
                        </TableCell>
                        {/* <TableCell
                            sx={{ width: '40%', border: 'none', padding: 0 ,paddingBottom: '10px', fontSize: '14px' }}
                        >{`${getReportStorageData(data.storage, 1)} ${getReportStorageData(
                            data.storage,
                            2
                        )} ${getReportStorageData(data.storage, 3)}`}</TableCell> */}
                    </TableRow>
                </TableBody>
            </Table>
            <Button
                sx={{ minWidth: '282px' }}
                startIcon={<Box mr={1} component='img' src={document} alt='Выход' />}
                variant='outlined'
                onClick={openReportDrawer}
            >
                Все детали отчёта
            </Button>
        </Box>
    )
}

export default observer(InformationReport)
