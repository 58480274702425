import {observer} from "mobx-react"
import React, {useCallback, useState} from "react"
import Box from "@mui/material/Box"
import {css} from "@emotion/css"
import Typography from "@mui/material/Typography"
import {useIntl} from "react-intl"
import {getStyles} from "../../../../utils/utils/commonUtils"
import {getComp} from "../../../../utils/utils/DI"
import chipsStyles from "../../../../components/Chips/styles/chipsStyles"
import DatesStore from "../../../../stores/DatesStore"
import Chip from "../../../../components/Chips/Chip"

function ReportFilterChip({propName, value, onClick,getStorageName}) {
    const classes = getStyles(chipsStyles)
    const intl = useIntl()
    const {getReportStatusName, getReportStatusImage, getFinancialSourceElement} = getComp("StatusesStore")
    const [datesStore] = useState(() => new DatesStore())
    const {getDealerName} = getComp("DealersStore")
    const setChipAction = useCallback(() => {
        return onClick ? () => onClick(propName, value) : null
    }, [value])

    const setValue = useCallback(() => {
        switch (propName) {
            case "statuses":
            case "status": {
                return (
                    <Box className={"aln-center"}>
                        <img alt={""} src={getReportStatusImage(value)} className={css(classes.statusIcon)} />
                        <Typography variant={"body1"} sx={classes.chipText}>
                            {getReportStatusName(value)}
                        </Typography>
                    </Box>
                )
            }
            case 'financialSourceIds': {
                
                const data = getFinancialSourceElement(value)
                 return(
                     <Box className={'aln-center'}>
                         <img alt={''} src={data.imageSrc} className={css(classes.statusIcon)} />
                         <Typography variant={'body1'} sx={classes.chipText}>
                             {data.name}
                         </Typography>
                     </Box>
                 )
             }
            case "creationDateFrom": {
                return intl.formatMessage({id: "Создан"}) + " " + value
            }
            case "closeDateFrom": {
                return intl.formatMessage({id: "Закрыт"}) + " " + value
            }
            case "dealerIds":
            case "dealerId": {
                return getDealerName(value)
            }
            case "month": {
                return datesStore.getMonthName(value, false, true)
            }
            case "shelvs":
            case "boxes": {
                return getStorageName(value)
            }
            default: {
                return value
            }
        }
    }, [value])

    return <Chip action={setChipAction()} value={setValue()} />
}

export default observer(ReportFilterChip)
