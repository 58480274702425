import {observer} from "mobx-react"
import {getStyles} from "src/utils/utils/commonUtils"
import {contentStyles} from "src/views/Reports/components/ReportStorageDrawer/Content/contentStyles"
import React, {useEffect, useState} from "react"
import InputField from "src/components/customElements/InputField/InputField"
import {FormattedMessage, useIntl} from "react-intl"
import {Box} from "@mui/system"
import {Button} from "@mui/material"
import StorageDrawerStore from "src/views/Reports/stores/StorageDrawerStore"

function Content() {
    const intl = useIntl()
    const classes = getStyles(contentStyles)
    const [storageDrawerStore] = useState(() => new StorageDrawerStore())
    const {
        selectedWarehouse,
        warehouses,
        isShelvInputDisabled,
        handleShelvChange,
        selectedShelv,
        shelvs,
        isBoxInputDisabled,
        handleBoxChange,
        boxes,
        selectedBox,
        isButtonAvailable,
        handleButtonClick,
        storagesStore
    } = storageDrawerStore

    const {storagesName, shelvName, boxName} = storagesStore

    return (
        <Box sx={classes.container}>
            <InputField
                type={"select"}
                value={selectedWarehouse}
                items={warehouses}
                label={storagesName}
                fullWidth={true}
                disabled={true}
            />
            <InputField
                type={"select"}
                value={selectedShelv}
                items={shelvs}
                disabled={isShelvInputDisabled}
                label={shelvName}
                fullWidth={true}
                onChange={handleShelvChange}
            />
            <InputField
                type={"select"}
                value={selectedBox}
                items={boxes}
                disabled={isBoxInputDisabled}
                label={boxName}
                fullWidth={true}
                onChange={handleBoxChange}
            />
            <Button
                disabled={!isButtonAvailable}
                variant={"contained"}
                color={"primary"}
                onClick={handleButtonClick}
                sx={{marginTop: "71px"}}
            >
                <FormattedMessage id={"Разместить"} />
            </Button>
        </Box>
    )
}

export default observer(Content)
