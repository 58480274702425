import {makeAutoObservable, reaction} from "mobx"
import {getReportStorages} from "src/services/ApiService"
import {openModalFn} from "src/utils/controllers/ModalController"
import StoragesStore from "src/views/Reports/stores/StoragesStore"

class StorageDrawerStore {
    storages = StoragesStore.storages
    selectedWarehouse = this.storages[0]?.id || {}
    selectedShelv = null
    selectedBox = null

    storagesStore = StoragesStore

    constructor() {
        makeAutoObservable(this)
    }

    get warehouses() {
        return this.storages.map((storage) => ({key: storage.name, value: storage.id}))
    }

    #getSelectedWarehouse = () => {
        return this.storages.find((storage) => storage.id === this.selectedWarehouse)
    }

    #getShelvs = () => {
        const warehouse = this.#getSelectedWarehouse()
        return warehouse ? warehouse.childs.map((shalving) => ({key: shalving.name, value: shalving.id})) : []
    }

    get shelvs() {
        return this.selectedWarehouse ? this.#getShelvs() : []
    }

    #getSelectedShelv = () => {
        const warehouse = this.#getSelectedWarehouse()
        return warehouse ? warehouse.childs.find((shalv) => shalv.id === this.selectedShelv) : []
    }

    #getBoxes = () => {
        const shalv = this.#getSelectedShelv()
        return shalv ? shalv.childs.map((box) => ({key: box.name, value: box.id})) : []
    }

    get boxes() {
        return this.selectedShelv ? this.#getBoxes() : []
    }

    handleShelvChange = (e) => {
        this.selectedShelv = e.target.value
    }

    get isShelvInputDisabled() {
        return !(this.selectedWarehouse && this.shelvs.length)
    }

    handleBoxChange = (e) => {
        this.selectedBox = e.target.value
    }

    get isBoxInputDisabled() {
        return !(this.selectedShelv && this.boxes.length)
    }

    get isButtonAvailable() {
        return !!this.selectedBox
    }

    #getSelectedBox = () => {
        const shalv = this.#getSelectedShelv()
        return shalv.childs.find((el) => el.id === this.selectedBox)
    }

    handleButtonClick = () => {
        openModalFn["add-to-storage"]({
            warehouse: this.#getSelectedWarehouse(),
            shelv: this.#getSelectedShelv(),
            box: this.#getSelectedBox()
        })
    }
}

export default StorageDrawerStore
