import { observer, Provider } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import ReportsStore from './stores/ReportsStore'
import ReportDetails from 'src/views/Reports/components/ReportDetails'
import ReportsContent from 'src/views/Reports/components/ReportsContent'
import CreateReport from 'src/views/Reports/components/CreateReport'
import { isDealer } from '../../services/AccountService'
import ReportDetailsAdvanced from './components/ReportDetailsAdvanced'

function Reports() {
    const [reportsStore] = useState(() => new ReportsStore())
    const {
        isAddRequestsToReportOpen,
        openAddRequestsToReport,
        closeAddRequestsToReport,
        isReportDetailsOpen,
        collection,
        openReportDetails,
        selectedReportId,
        closeReportDetails,
        isCreateReportOpen,
        closeCreateReport,
        created,
        setCreated,
    } = reportsStore

    useEffect(() => {
        if (created) {
            openReportDetails({
                data: collection[0],
                rowIndex: 0,
            })
        }
    }, [collection])
    return (
        <Provider reportStore={reportsStore}>
            {isReportDetailsOpen ? (
                isDealer() ? (
                    <ReportDetailsAdvanced
                        selectedReportId={selectedReportId}
                        onClose={closeReportDetails}
                        isReportDetailsOpen={isReportDetailsOpen}
                        closeCreateReport={closeCreateReport}
                        openAddRequestsToReport={openAddRequestsToReport}
                        isAddRequestsToReportOpen={isAddRequestsToReportOpen}
                        closeAddRequestsToReport={closeAddRequestsToReport}
                    />
                ) : (
                    <ReportDetails
                        selectedReportId={selectedReportId}
                        onClose={closeReportDetails}
                        isReportDetailsOpen={isReportDetailsOpen}
                    />
                )
            ) : isCreateReportOpen ? (
                <CreateReport created={created} setCreated={setCreated} closeCreateReport={closeCreateReport} />
            ) : (
                <ReportsContent />
            )}
        </Provider>
    )
}

export default observer(Reports)
