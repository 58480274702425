import OrdersStore from "src/stores/OrdersStore"
import RequestsFilterStore from "src/views/RequestsManagement/stores/RequestsFilterStore"
import SelectOrdersStore from "src/stores/SelectOrdersStore"
import {closeModalFn, openModalFn} from "src/utils/controllers/ModalController"
import {notifySuccess} from "src/services/ToastifyService/ToastifyService"
import {createReport} from "src/services/ApiService"
import {computed, makeObservable} from "mobx"

class CreateReportStore {
    constructor({closeCreateReport, isAddRequestsToReportOpen}) {
        this.closeCreateReport = closeCreateReport
        this.isAddRequestsToReportOpen = isAddRequestsToReportOpen
        this.ordersStore =  new OrdersStore({isAgreement: false, isAddRequestsToReportOpen: isAddRequestsToReportOpen})
        this.filterStore = new RequestsFilterStore()
        this.selectOrdersStore = new SelectOrdersStore({from: "reports",ordersStore: this.ordersStore})
        makeObservable(this,{
            defaultParams: computed
        })
    }

    get defaultParams() {
        return {
            status: 6,
            page: 0,
            dataCheckingStatus: [0, 3],
            size: 2000
        }
    }

    createReport = () => {
        openModalFn["progress-backdrop"]()
        createReport({OrderIdList: this.selectOrdersStore.selectedOrders})
            .then((res) => {
                this.closeCreateReport()
                notifySuccess(`Отчет ${res.id ? `№${res.id}` : ""} сформирован`)
            })
            .finally(() => closeModalFn["progress-backdrop"]())
    }
}

export default CreateReportStore