import { Box } from '@mui/material'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import NameReport from './components/NameReport'
import InformationReport from './components/InformationReport'

const styles = {
    container: {
        width: '100%',
        padding: '4px 0px',
        borderRadius: '8px',
        background: '#FFF',
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.15)',
        overflow: 'hidden',
    },
    informationReport: {
        borderTop: '1px solid #E0E0E0',
        transition: 'max-height 1s ease, opacity 0.5s ease',
        maxHeight: 0,
        opacity: 0,
    },
    informationReportOpen: {
        maxHeight: '120px', // значение с запасом
        opacity: 1,
    },
}

function SelectedReport({reportTitle, onClose, data, openReportDrawer, total}) {
    const [openInfarmationReport, setOpenInfarmationReport] = useState(true)

    return (
        <Box mb={2} sx={styles.container}>
            <NameReport onClose={onClose} reportTitle={reportTitle} openInfarmationReport={openInfarmationReport} setOpenInfarmationReport={setOpenInfarmationReport} />
            <Box
                sx={{
                    ...styles.informationReport,
                    ...(openInfarmationReport ? styles.informationReportOpen : {}),
                }}
            >
                <InformationReport total={total}  openReportDrawer={openReportDrawer} data={data} />
            </Box>
        </Box>
    )
}

export default observer(SelectedReport)
